export const useHeaderOffset = () => {
  const route = useRoute();

  const headerOffset = useState<number>('headerOffset', () => 0);
  const headerRef = useState<{offsetHeight: number} | undefined>('headerRef', () => undefined);

  onMounted(() => headerOffset.value = headerRef.value?.offsetHeight ?? 0);
  watch([route, headerRef], () => nextTick().then(() => headerOffset.value = headerRef.value?.offsetHeight ?? 0));

  return {
    headerRef,
    headerOffset,
  };
};
